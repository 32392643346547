import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Icon from 'components/icon'
import GotoDemoHero from 'components/hero/gotoDemo'
import { redirectTo } from 'utils'

const OriginShieldPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('originShieldPage.meta.title'),
            metaDescription: t('originShieldPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/featrues/origin-shield/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex flex-col-reverse md:flex-row gap-5 pb-32 pt-24">
          <div className="flex-1">
            <h1 className="font-normal mb-5 text-primary-400 text-lg">{t('originShieldPage.section1.subtitle')}</h1>
            <h2 className="leading-snug text-primary-500 text-4xl lg:text-5xl">
              {t('originShieldPage.section1.title')}
            </h2>
            <p className="py-10 text-xl text-gray-500">{t('originShieldPage.section1.description')}</p>
            <div className="flex flex-col lg:flex-row gap-3 my-1">
              <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                {t('button.freeTrial.0')}
              </Link>
              <Link
                to="/lp/get-a-demo/"
                className="btn btn-bold btn-ghost btn-orange inline-block"
                style={{ width: 208 }}
              >
                {t('button.getDemo.0')}
              </Link>
            </div>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <img
              src="https://www.mlytics.com/wp-content/uploads/2022/05/OS_hero_02.svg"
              alt={t('originShieldPage.section1.subtitle')}
              style={{ height: 'auto', maxWidth: '550px' }}
            />
          </div>
        </section>
        <section className="bg-primary-500 px-4 py-20">
          <div className="container">
            <h2 className="text-center text-white text-4xl">{t('originShieldPage.section2.title')}</h2>
            <p className="leading-7 m-auto max-w-[800px] pt-6 text-center text-white">
              {t('originShieldPage.section2.description')}
            </p>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/11/Cloud_WAF_1.svg"
                alt={t('originShieldPage.section3.subtitle')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('originShieldPage.section3.subtitle')}</div>
              <h2 className="text-3xl">{t('originShieldPage.section3.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('originShieldPage.section3.description')}</p>
              <button
                className="font-bold flex items-center text-secondary-600 hover:text-secondary-700 duration-200 ease-linear"
                onClick={redirectTo('https://www.mlytics.com/features/web-application-firewall/')}
              >
                {t('button.learnMore')}
                <Icon className="ml-1" fontSize="16px" name="arrow_forward" />
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/11/Cloud_WAF_3.svg"
                alt={t('originShieldPage.section4.subtitle')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('originShieldPage.section4.subtitle')}</div>
              <h2 className="text-3xl">{t('originShieldPage.section4.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('originShieldPage.section4.description')}</p>
              <button
                className="font-bold flex items-center text-secondary-600 hover:text-secondary-700 duration-200 ease-linear"
                onClick={redirectTo('https://www.mlytics.com/features/web-firewall/')}
              >
                {t('button.learnMore')}
                <Icon className="ml-1" fontSize="16px" name="arrow_forward" />
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/11/Origin-DDoS-protection-2.svg"
                alt={t('originShieldPage.section5.subtitle')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('originShieldPage.section5.subtitle')}</div>
              <h2 className="text-3xl">{t('originShieldPage.section5.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('originShieldPage.section5.description')}</p>
              <button
                className="font-bold flex items-center text-secondary-600 hover:text-secondary-700 duration-200 ease-linear"
                onClick={redirectTo('https://www.mlytics.com/features/ddos-protection/')}
              >
                {t('button.learnMore')}
                <Icon className="ml-1" fontSize="16px" name="arrow_forward" />
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/11/Access_control-1.svg"
                alt={t('originShieldPage.section6.subtitle')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('originShieldPage.section6.subtitle')}</div>
              <h2 className="text-3xl">{t('originShieldPage.section6.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('originShieldPage.section6.description')}</p>
              <button
                className="font-bold flex items-center text-secondary-600 hover:text-secondary-700 duration-200 ease-linear"
                onClick={redirectTo('https://www.mlytics.com/features/access-control/')}
              >
                {t('button.learnMore')}
                <Icon className="ml-1" fontSize="16px" name="arrow_forward" />
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/11/HTTP-header-rewrite-1.svg"
                alt={t('originShieldPage.section7.subtitle')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('originShieldPage.section7.subtitle')}</div>
              <h2 className="text-3xl">{t('originShieldPage.section7.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('originShieldPage.section7.description')}</p>
              <button
                className="font-bold flex items-center text-secondary-600 hover:text-secondary-700 duration-200 ease-linear"
                onClick={redirectTo('https://www.mlytics.com/features/http-header-rewrite/')}
              >
                {t('button.learnMore')}
                <Icon className="ml-1" fontSize="16px" name="arrow_forward" />
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/11/Origin-services-3.svg"
                alt={t('originShieldPage.section8.subtitle')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('originShieldPage.section8.subtitle')}</div>
              <h2 className="text-3xl">{t('originShieldPage.section8.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('originShieldPage.section8.description')}</p>
              <button
                className="font-bold flex items-center text-secondary-600 hover:text-secondary-700 duration-200 ease-linear"
                onClick={redirectTo('https://www.mlytics.com/features/origin-service/')}
              >
                {t('button.learnMore')}
                <Icon className="ml-1" fontSize="16px" name="arrow_forward" />
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/11/Bot_management_hero-1_01.svg"
                alt={t('originShieldPage.section9.subtitle')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('originShieldPage.section9.subtitle')}</div>
              <h2 className="text-3xl">{t('originShieldPage.section9.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('originShieldPage.section9.description')}</p>
              <button
                className="font-bold flex items-center text-secondary-600 hover:text-secondary-700 duration-200 ease-linear"
                onClick={redirectTo('https://www.mlytics.com/features/bot-management/')}
              >
                {t('button.learnMore')}
                <Icon className="ml-1" fontSize="16px" name="arrow_forward" />
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/08/API-protection_Requirement-based-customization-1.svg"
                alt={t('originShieldPage.section10.subtitle')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('originShieldPage.section10.subtitle')}</div>
              <h2 className="text-3xl">{t('originShieldPage.section10.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('originShieldPage.section10.description')}</p>
              <button
                className="font-bold flex items-center text-secondary-600 hover:text-secondary-700 duration-200 ease-linear"
                onClick={redirectTo('https://www.mlytics.com/features/api-protection/')}
              >
                {t('button.learnMore')}
                <Icon className="ml-1" fontSize="16px" name="arrow_forward" />
              </button>
            </div>
          </div>
        </section>
        <GotoDemoHero />
      </React.Fragment>
    </Layout>
  )
}

export default OriginShieldPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
